import React, { FC, ReactNode, ReactElement, useRef } from 'react';
import ReactToPrint, { ITriggerProps } from 'react-to-print';
import { PrintIcon, PrintButton, PageWrappes, GoBackArrow } from './PrintWrapperStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

interface PrinterWrapperProps {
    children: ReactNode;
    isShmPage?: boolean;
    shouldHasGoBackArrow?: boolean;
}

const PrinterWrapper: FC<PrinterWrapperProps> = ({ children, isShmPage, shouldHasGoBackArrow }) => {
    const { t } = useTranslation();
    const componentRef = useRef();

    const linkToPrint = (): ReactElement<ITriggerProps<any>> => {
        if (isShmPage) {
            return <PrintIcon />;
        } else {
            return (
                <PrintButton>
                    {t('printPage')} <PrintIcon />
                </PrintButton>
            );
        }
    };

    const goBackArrow = (): ReactNode => (
        <PrintButton onClick={() => navigate(-1)}>
            <GoBackArrow />
        </PrintButton>
    );

    return (
        <>
            <div ref={componentRef}>{children}</div>
            <PageWrappes>
                {shouldHasGoBackArrow && goBackArrow()}
                <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />
            </PageWrappes>
        </>
    );
};

export default PrinterWrapper;
