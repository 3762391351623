import styled from 'styled-components';
import { AiFillPrinter } from 'react-icons/ai';
import { greenPrintDarker, greenPrint, white, containerWidth } from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';
import { ImArrowLeft } from 'react-icons/im';

export const PrintIcon = styled(AiFillPrinter)`
    fill: ${greenPrint};
    font-size: 1.5em;
    transition: 0.4s;
    margin-left: 0.2em;
    cursor: pointer;
`;

export const GoBackArrow = styled(ImArrowLeft)`
    fill: ${greenPrint};
    font-size: 1.5em;
    margin-right: 0.5rem;
`;

export const PrintButton = styled.button`
    background-color: ${white};
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    color: ${greenPrint};
    border: none;
    cursor: pointer;
    transition: 0.4s;
    display: flex;
    align-items: center;
    &:hover {
        color: ${greenPrintDarker};
    }
    &:hover ${PrintIcon} {
        fill: ${greenPrintDarker};
    }

    @media ${deviceMax.laptop} {
        padding: 0 15px;
    }
`;

export const PageWrappes = styled.div`
    width: ${containerWidth};
    display: flex;
    margin: 2em auto;
    @media ${deviceMax.laptop} {
        width: unset;
    }
`;
